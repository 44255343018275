import {
	createRouter,
	createWebHistory
} from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'

const routes = [{
		path: '/',
		redirect: 'Home'
		// component: HomeView
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			title: "雨诺股份，重构医药大健康数字化能力"
		},
	},
	{
		path: '/enterpriseSolution',
		name: 'EnterpriseSolution',
		meta: {
			title: "集团企业解决方案"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/EnterpriseSolution.vue')
	},
	{
		path: '/groupSolution',
		name: 'GroupSolution',
		meta: {
			title: "医药新零售解决方案"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/GroupSolution.vue')
	},
	{
		path: '/smallSolution',
		name: 'SmallSolution',
		meta: {
			title: "小微企业解决方案"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/SmallSolution.vue')
	},
	{
		path: '/mediumSolotion',
		name: 'MediumSolotion',
		meta: {
			title: "中大型企业解决方案"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/MediumSolotion.vue')
	},
	{
		path: '/productCenterOms',
		name: 'ProductCenterOms',
		meta: {
			title: "OMS订单中台"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterOms.vue')
	},
	{
		path: '/productCenterHospital',
		name: 'ProductCenterHospital',
		meta: {
			title: "医馆云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterHospital.vue')
	},
	{
		path: '/productCenterInsurancel',
		name: 'ProductCenterInsurancel',
		meta: {
			title: "医保云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterInsurancel.vue')
	},
	{
		path: '/productCenterMicroenterprise',
		name: 'ProductCenterMicroenterprise',
		meta: {
			title: "小微供应链云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterMicroenterprise.vue')
	},
	{
		path: '/productCenterGroup',
		name: 'ProductCenterGroup',
		meta: {
			title: "集团供应链云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterGroup.vue')
	},
	{
		path: '/productCenterChain',
		name: 'ProductCenterChain',
		meta: {
			title: "连锁供应链云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterChain.vue')
	},
	{
		path: '/productCenterWms',
		name: 'ProductCenterWms',
		meta: {
			title: "WMS"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterWms.vue')
	},
	{
		path: '/productCenterDtp',
		name: 'ProductCenterDtp',
		meta: {
			title: "DTP管理"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterDtp.vue')
	},
	{
		path: '/productCenterCrm',
		name: 'ProductCenterCrm',
		meta: {
			title: "会员CRM云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterCrm.vue')
	},
	{
		path: '/productCenterStore',
		name: 'ProductCenterStore',
		meta: {
			title: "门店云"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterStore.vue')
	},
	{
		path: '/productCenterNcd',
		name: 'ProductCenterNcd',
		meta: {
			title: "慢病管理"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterNcd.vue')
	},
	{
		path: '/productCenterPrescription',
		name: 'ProductCenterPrescription',
		meta: {
			title: "处方平台"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterPrescription.vue')
	},
	{
		path: '/productCenterPrivate',
		name: 'ProductCenterPrivate',
		meta: {
			title: "私域商城"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductCenterPrivate.vue')
	},
	{
		path: '/ecologicalCooperation',
		name: 'EcologicalCooperation',
		meta: {
			title: "生态合作"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/EcologicalCooperation.vue')
	},
	{
		path: '/newsCenter',
		name: 'NewsCenter',
		meta: {
			title: "新闻中心"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsCenter.vue')
	},
	{
		path: '/newsInfor1',
		name: 'NewsInfor1',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor1.vue')
	},
	{
		path: '/newsInfor2',
		name: 'NewsInfor2',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor2.vue')
	},
	{
		path: '/newsInfor3',
		name: 'NewsInfor3',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor3.vue')
	},
	{
		path: '/newsInfor4',
		name: 'NewsInfor4',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor4.vue')
	},
	{
		path: '/newsInfor5',
		name: 'NewsInfor5',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor5.vue')
	},
	{
		path: '/newsInfor6',
		name: 'NewsInfor6',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor6.vue')
	},
	{
		path: '/newsInfor7',
		name: 'NewsInfor7',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor7.vue')
	},
	{
		path: '/newsInfor8',
		name: 'NewsInfor8',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor8.vue')
	},
	{
		path: '/newsInfor9',
		name: 'NewsInfor9',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor9.vue')
	},
	{
		path: '/newsInfor10',
		name: 'NewsInfor10',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor10.vue')
	},
	{
		path: '/newsInfor11',
		name: 'NewsInfor11',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor11.vue')
	},
	{
		path: '/newsInfor12',
		name: 'NewsInfor12',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor12.vue')
	},
	{
		path: '/newsInfor13',
		name: 'NewsInfor13',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor13.vue')
	},
	{
		path: '/newsInfor14',
		name: 'NewsInfor14',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor14.vue')
	},
	{
		path: '/newsInfor15',
		name: 'NewsInfor15',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor15.vue')
	},
	{
		path: '/newsInfor16',
		name: 'NewsInfor16',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor16.vue')
	},
	{
		path: '/newsInfor17',
		name: 'NewsInfor17',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor17.vue')
	},
	{
		path: '/newsInfor18',
		name: 'NewsInfor18',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor18.vue')
	},
	{
		path: '/newsInfor19',
		name: 'NewsInfor19',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor19.vue')
	},
	{
		path: '/newsInfor20',
		name: 'NewsInfor20',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor20.vue')
	},
	{
		path: '/newsInfor21',
		name: 'NewsInfor21',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor21.vue')
	},
	{
		path: '/newsInfor22',
		name: 'NewsInfor22',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor22.vue')
	},
	{
		path: '/newsInfor23',
		name: 'NewsInfor23',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor23.vue')
	},
	{
		path: '/newsInfor24',
		name: 'NewsInfor24',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor24.vue')
	},
	{
		path: '/newsInfor25',
		name: 'NewsInfor25',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor25.vue')
	},
	{
		path: '/newsInfor26',
		name: 'NewsInfor26',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor26.vue')
	},
	{
		path: '/newsInfor27',
		name: 'NewsInfor27',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor27.vue')
	},
	{
		path: '/newsInfor28',
		name: 'NewsInfor28',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor28.vue')
	},
	{
		path: '/newsInfor29',
		name: 'NewsInfor29',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor29.vue')
	},
	{
		path: '/newsInfor30',
		name: 'NewsInfor30',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor30.vue')
	},
	{
		path: '/newsInfor31',
		name: 'NewsInfor31',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor31.vue')
	},
	{
		path: '/newsInfor32',
		name: 'NewsInfor32',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor32.vue')
	},
	{
		path: '/newsInfor33',
		name: 'NewsInfor33',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor33.vue')
	},
	{
		path: '/newsInfor34',
		name: 'NewsInfor34',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor34.vue')
	},
	{
		path: '/newsInfor35',
		name: 'NewsInfor35',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor35.vue')
	},
	{
		path: '/newsInfor36',
		name: 'NewsInfor36',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor36.vue')
	},
	{
		path: '/newsInfor37',
		name: 'NewsInfor37',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor37.vue')
	},
	{
		path: '/newsInfor38',
		name: 'NewsInfor38',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor38.vue')
	},
	{
		path: '/newsInfor39',
		name: 'NewsInfor39',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor39.vue')
	},
	{
		path: '/newsInfor40',
		name: 'NewsInfor40',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor40.vue')
	},
	{
		path: '/newsInfor41',
		name: 'NewsInfor41',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor41.vue')
	},
	{
		path: '/newsInfor42',
		name: 'NewsInfor42',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor42.vue')
	},
	{
		path: '/newsInfor43',
		name: 'NewsInfor43',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor43.vue')
	},
	{
		path: '/newsInfor44',
		name: 'NewsInfor44',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor44.vue')
	},
	{
		path: '/newsInfor45',
		name: 'NewsInfor45',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor45.vue')
	},
	{
		path: '/newsInfor46',
		name: 'NewsInfor46',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor46.vue')
	},
	{
		path: '/newsInfor47',
		name: 'NewsInfor47',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor47.vue')
	},
	{
		path: '/newsInfor48',
		name: 'NewsInfor48',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor48.vue')
	},
	{
		path: '/newsInfor49',
		name: 'NewsInfor49',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor49.vue')
	},
	{
		path: '/newsInfor50',
		name: 'NewsInfor50',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor50.vue')
	},
	{
		path: '/newsInfor51',
		name: 'NewsInfor51',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor51.vue')
	},
	{
		path: '/newsInfor52',
		name: 'NewsInfor52',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor52.vue')
	},
	{
		path: '/newsInfor53',
		name: 'NewsInfor53',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor53.vue')
	},
	{
		path: '/newsInfor54',
		name: 'NewsInfor54',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor54.vue')
	},
	{
		path: '/newsInfor55',
		name: 'NewsInfor55',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor55.vue')
	},
	{
		path: '/newsInfor56',
		name: 'NewsInfor56',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor56.vue')
	},
	{
		path: '/newsInfor57',
		name: 'NewsInfor57',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor57.vue')
	},
	{
		path: '/newsInfor58',
		name: 'NewsInfor58',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor58.vue')
	},
	{
		path: '/newsInfor59',
		name: 'NewsInfor59',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor59.vue')
	},
	{
		path: '/newsInfor60',
		name: 'NewsInfor60',
		meta: {
			title: "新闻详情"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/NewsInfor60.vue')
	},
	{
		path: '/aboutUs',
		name: 'AboutUs',
		meta: {
			title: "公司简介"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutUs.vue')
	},
	{
		path: '/contactUs',
		name: 'ContactUs',
		meta: {
			title: "联系我们"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/ContactUs.vue')
	},
	{
		path: '/talentRecruitment',
		name: 'TalentRecruitment',
		meta: {
			title: "人才招聘"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/TalentRecruitment.vue')
	},
	{
		path: '/socialResponsibility',
		name: 'SocialResponsibility',
		meta: {
			title: "社会责任"
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/SocialResponsibility.vue')
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	// history: createWebHashHistory(process.env.BASE_URL),
	routes
})
router.beforeEach((to, from, next) => {
	if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
	  window.location.href = 'http://m.romens.cn'
	  return
	}
	next()
})


export default router
